import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { HeadingLevel } from '@/components/common/MarkUp';

import type { ReactNode } from 'react';

export type ColumnsSplitProps =
  | 'even'
  | 'imageLeft'
  | 'imageRight'
  | 'singleColumn'
  | 'singleColumnReverse';
export interface TwoColumnWrapperProps {
  children: ReactNode;
  columnsSplit: ColumnsSplitProps;
}

export const TwoColumnWrapper = ({
  children,
  columnsSplit,
}: TwoColumnWrapperProps) => {
  return (
    <HeadingLevel>
      <TwoColumnGridContainer columnsSplit={columnsSplit}>
        {children}
      </TwoColumnGridContainer>
    </HeadingLevel>
  );
};

const TwoColumnGridContainer = styled.div<{ columnsSplit }>`
  display: flex;

  ${({ columnsSplit }) => {
    switch (columnsSplit) {
      case 'even':
        return css`
          flex-direction: column;

          ${screen.md} {
            flex-direction: row;
            justify-content: center;
            gap: var(--spacing-800);
            > div {
              width: calc(50% - var(--spacing-800));
            }
          }

          ${screen.lg} {
            gap: var(--spacing-1000);
            > div {
              width: calc(50% - var(--spacing-1000));
            }
          }
        `;

      case 'imageLeft':
        return css`
          flex-direction: column;
          gap: var(--spacing-300);

          ${screen.md} {
            flex-direction: row;
            gap: var(--spacing-500);

            > div:first-of-type {
              width: calc(58% - (var(--spacing-500) / 2));
            }

            > div:nth-of-type(2) {
              width: calc(42% - (var(--spacing-500) / 2));
            }
          }
        `;

      case 'imageRight':
        return css`
          flex-direction: column-reverse;
          gap: var(--spacing-300);

          ${screen.md} {
            gap: var(--spacing-500);
            flex-direction: row-reverse;

            > div:first-of-type {
              width: calc(42% - (var(--spacing-500) / 2));
            }

            > div:nth-of-type(2) {
              width: calc(58% - (var(--spacing-500) / 2));
            }
          }
        `;
      case 'singleColumn':
        return css`
          flex-direction: column-reverse;

          gap: var(--spacing-300);

          > div {
            align-items: center;
            text-align: center;
          }

          img {
            max-width: 800px;
          }

          ${screen.md} {
            gap: var(--spacing-500);
          }
        `;

      case 'singleColumnReverse':
        return css`
          flex-direction: column;
          gap: var(--spacing-300);

          > div {
            align-items: center;
            text-align: center;
          }
          img {
            max-width: 800px;
          }

          ${screen.md} {
            gap: var(--spacing-500);
          }
        `;

      default:
        return css`
          flex-direction: 'column';
          gap: var(--spacing-400);

          ${screen.md} {
            flex-direction: row;
            > div {
              width: calc(50% - (var(--spacing-small) / 2));
            }
          }
        `;
    }
  }};
`;
