import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { TwoColumnWrapper } from '@/components/blocks/TwoColumnLayout/TwoColumnWrapper';
import { Badge } from '@/components/common/Badge/Badge';
import { screen } from '@/components/common/breakpoints';
import { Button } from '@/components/common/Buttons';
import { CaptionHeadingDescription } from '@/components/common/CaptionHeadingDescription';
import { Image } from '@/components/common/image';
import { ConditionalAnimateWrapper } from '@/components/common/utils';
import { WistiaVideoPlayer } from '@/components/common/WistiaComponents/WistiaVideoPlayer';
import { Section } from '@/components/layout/Section';
import { getTextColour } from '@/lib/colourUtils';
import { blockUrlWithAudience } from '@/lib/utils';

import type {
  SanityAudienceType,
  SanityProductHighlightType,
} from '@/types/sanity';
import type {
  BlockWithAudience,
  CTAProps,
  StandardImage,
} from '@/types/shared';

export interface ActionProps
  extends Pick<CTAProps, 'buttonAccessibleLabel' | 'buttonLink'> {
  _type: 'action';
  buttonLabel?: string;
  audienceRef?: SanityAudienceType;
  noAudienceToggle?: boolean;
  linkTrackingTag?: {
    _type: 'linkTrackingTag';
    blockName: string;
    blockAction: string;
  };
}

export interface ProductHighlightProps
  extends Omit<SanityProductHighlightType, 'action'>,
    BlockWithAudience {
  action?: ActionProps;
}

export const ProductHighlight = ({
  caption,
  heading,
  subheading,
  action,
  animate,
  tag,
  image,
  wistiaPopover,
  columnsSplit = 'even',
  isCaptionUppercase = false,
  backgroundColour,
  blockSpacing,
  paddingAdjustment,
  pageAudience,
  isAudienceSwitcherEnabled,
}: ProductHighlightProps) => {
  const hasDefaultImage = image?.asset?._ref === undefined;

  const backgroundColourToken =
    backgroundColour?.token || '--background-subtle';

  return (
    <Section
      verticalPadding={paddingAdjustment ?? true}
      spacing={blockSpacing}
      backgroundColour={backgroundColourToken}
      colour={getTextColour(backgroundColourToken)}
      className="product-highlight"
    >
      <ConditionalAnimateWrapper animate={animate}>
        <TwoColumnWrapper columnsSplit={columnsSplit}>
          <ImageContainer columnsSplit={columnsSplit}>
            {wistiaPopover?.videoId ? (
              <WistiaVideoPlayer
                image={image as StandardImage}
                wistiaPopover={wistiaPopover}
              />
            ) : (
              <Image
                asset={image.asset}
                alt={image?.alternateText}
                isDecorativeImage={image?.isDecorativeImage}
              />
            )}
          </ImageContainer>

          <CopyContainer hasDefaultImage={hasDefaultImage}>
            {tag?.tagText && (
              <Badge
                bgColour={tag?.tagColour}
                margin="0 0 var(--spacing-xxx-small) 0"
              >
                {tag.tagText}
              </Badge>
            )}
            <StyledCaptionHeadingDescription
              caption={caption}
              heading={heading}
              description={subheading}
              desktopAlignment={
                columnsSplit === 'singleColumn' ||
                columnsSplit === 'singleColumnReverse'
                  ? 'center'
                  : 'start'
              }
              mobileAlignment="center"
              isCaptionUppercase={isCaptionUppercase}
            />
            {action?.buttonLabel && (
              <Button
                iconPosition="right"
                href={blockUrlWithAudience(
                  action.buttonLink,
                  action.audienceRef,
                  pageAudience,
                  action.noAudienceToggle,
                  isAudienceSwitcherEnabled,
                )}
                aria-label={action.buttonAccessibleLabel}
                variant="solid-light"
                className={
                  action.linkTrackingTag &&
                  `tracking-${action.linkTrackingTag.blockName}__${action.linkTrackingTag.blockAction}`
                }
              >
                {action.buttonLabel}
              </Button>
            )}
          </CopyContainer>
        </TwoColumnWrapper>
      </ConditionalAnimateWrapper>
    </Section>
  );
};

const ImageContainer = styled.div<{ columnsSplit: string }>`
  align-self: center;

  > img {
    max-height: 560px;
    max-width: 660px;
    border-radius: var(--radius-l);
    height: auto;
  }

  ${screen.md} {
    > img {
      max-height: 900px;
      max-width: 900px;
    }
  }

  ${({ columnsSplit }) =>
    columnsSplit === 'imageLeft' &&
    css`
      position: relative;
      overflow: hidden;

      > img {
        margin-bottom: -2.6rem;
      }

      ${screen.sm} {
        border: none;
        overflow: visible;

        > img {
          margin-bottom: 0;
        }
      }
    `}
`;

const StyledCaptionHeadingDescription = styled(CaptionHeadingDescription)`
  margin-bottom: var(--spacing-x-large);
`;

const CopyContainer = styled.div<{ hasDefaultImage: boolean }>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  z-index: 2;
  position: relative;
  padding-top: var(--spacing-400);

  ${screen.md} {
    &::before {
      content: none;
    }

    padding-top: 0;
    align-items: flex-start;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }
`;
